import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface PeptideContent {
  peptide: string;
  ratio: number;
}

interface SecondData {
  id: string;
  content: PeptideContent[];
}

interface BubbleChartProps {
  data: SecondData[];
  width?: number | string; // Permet d'utiliser des unités relatives comme '50vw'
  height?: number | string;
  currentChemicalFamily?: string;
}

interface BubbleData extends d3.SimulationNodeDatum {
  id: string;
  ratio: number;
  peptide: string;
  x?: number; // Position x pour D3
  y?: number;
}

const BubbleChart: React.FC<BubbleChartProps> = ({ data, width = '100%', height = 600, currentChemicalFamily }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const simulationRef = useRef<d3.Simulation<BubbleData, undefined> | null>(null);

  const formatData = (data: SecondData[]): BubbleData[] => {
    return data.flatMap((d) =>
      d.content.map((peptide) => ({
        id: `${d.id}-${peptide.peptide}`,
        ratio: peptide.ratio,
        peptide: peptide.peptide,
      }))
    );
  };

  useEffect(() => {
    const initialData = formatData(data);
    console.log('initialData', initialData);

    // Définition des dimensions
    // Conversion des valeurs width et height en nombre si elles sont fournies en pixels, sinon fallback par défaut
    const widthValue = typeof width === 'number' ? width : svgRef.current?.clientWidth || 500;
    const heightValue = typeof height === 'number' ? height : svgRef.current?.clientHeight || 300;

    const centerX = widthValue / 2;
    const centerY = heightValue / 2;

    const colorScale = d3.scaleOrdinal<string, string>(d3.schemeCategory10);

    const svg = d3.select(svgRef.current).attr('width', widthValue).attr('height', heightValue);

    // Simulation de forces
    const simulation = d3
      .forceSimulation<BubbleData>(initialData)
      .force('center', d3.forceCenter().x(centerX).y(centerY))
      .force('charge', d3.forceManyBody().strength(0.5))
      .force(
        'collision',
        d3
          .forceCollide<BubbleData>()
          .strength(0.7)
          .radius((d) => d.ratio * 5 + 5)
      )
      .force('confinement', () => {
        initialData.forEach((d) => {
          d.x = Math.max(d.ratio * 5 + 5, Math.min(widthValue - (d.ratio * 5 + 5), d.x ?? 0));
          d.y = Math.max(d.ratio * 5 + 5, Math.min(heightValue - (d.ratio * 5 + 5), d.y ?? 0));
        });
      })
      .on('tick', ticked);

    simulationRef.current = simulation;

    function ticked() {
      svg
        .selectAll('circle')
        .data(simulation.nodes() as BubbleData[], (d) => (d as BubbleData).id)
        .join('circle')
        .attr('cx', (d) => d.x ?? centerX)
        .attr('cy', (d) => d.y ?? centerY)
        .attr('fill', (d) => colorScale(d.peptide.toString()))
        .attr('stroke', '#fff')
        .attr('stroke-width', 2);

      svg
        .selectAll('text')
        .data<BubbleData>(simulation.nodes(), (d) => (d as BubbleData).id)
        .join('text')
        .attr('x', (d) => d.x ?? centerX)
        .attr('y', (d) => (d.y ?? centerY) + 4) // Décalage léger pour centrer verticalement
        .attr('text-anchor', 'middle')
        .attr('font-size', '14px')
        .attr('fill', '#000')
        .text((d) => d.peptide); // Affiche le ratio avec 2 décimales
    }

    return () => {
      simulation.stop();
    };
  }, [width, height]);

  useEffect(() => {
    if (simulationRef.current) {
      const newData = formatData(data);
      // console.log('newData', newData);

      const nodes = simulationRef.current.nodes() as BubbleData[];
      newData.forEach((newNode) => {
        const existingNode = nodes.find((node) => node.peptide === newNode.peptide);
        if (existingNode) {
          existingNode.ratio = newNode.ratio;
        }
      });

      const svg = d3.select(svgRef.current);
      svg
        .selectAll('circle')
        .data(nodes, (d) => (d as BubbleData).id)
        .join('circle')
        .transition()
        .duration(500)
        .attr('r', (d) => Math.max(d.ratio * 5, 5));

      svg
        .selectAll('text')
        .data(nodes, (d) => (d as BubbleData).id)
        .join('text')
        .transition()
        .duration(500)
        .attr('x', (d) => d.x ?? 0)
        .attr('y', (d) => (d.y ?? 0) + 4)
        .text((d) => d.peptide);

      simulationRef.current.force(
        'collision',
        d3
          .forceCollide<BubbleData>()
          .radius((d) => Math.max(d.ratio * 5 + 5))
          .strength(0.7)
      );
      simulationRef.current.alpha(0.05).restart();
    }
  }, [data]);

  return (
    <svg
      ref={svgRef}
      style={{
        width: typeof width === 'string' ? width : `${width}px`,
        height: typeof height === 'string' ? height : `${height}px`,
      }}
    ></svg>
  );
};

export default BubbleChart;
