import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { Matrix } from 'ml-matrix';
import { loadGenericModel, loadSpotsGrid1D } from '../../../services/cache/localStorage';
import { classifySignatureChemical } from '../../analysis/classifier';
import { Paper } from '../../common/common';
import { mean } from '../../analysis/utils';
import { Collapse } from 'antd';
import { GenericModel } from '../../analysis/definitions';

const { Panel } = Collapse;

const DEFAULT_PLOTLY_MARGIN: Plotly.Layout['margin'] = {
  t: 30,
  b: 40,
  l: 40,
  r: 40,
};

const DISPLAY_RADIAN_THRESHOLD = 0.3;

interface DataProcessorProps {
  rawSignalFromApp: number[] | null;
  correctedSignalFromApp: number[] | null;
  seriesLabels: number[] | null;
  setCurrentProba: React.Dispatch<React.SetStateAction<SecondData[]>>;
  setCurrentChemicalFamily: React.Dispatch<React.SetStateAction<string>>
}

type MZIPackage = {
  rawMZI: number[],
  rawMZIwithHumidityCorrection: number[],
}

type packagedDisplay = {
  aggregatedRawMZIforDisplay: number[],
  aggregatedRawMZIHumidityCorrectedforDisplay: number[],
  aggregatedNormalizedforDisplay: number[],
  aggregatedNormalizedHumidityCorrectedforDisplay: number[]
}

interface PeptideContent {
  peptide: string;
  ratio: number;
}

interface SecondData {
  id: string;
  content: PeptideContent[];
}







const DataProcessor: React.FC<DataProcessorProps> = ({ rawSignalFromApp, correctedSignalFromApp, seriesLabels, setCurrentProba, setCurrentChemicalFamily }) => {
  const [plotlyData, setPlotlyData] = useState<Partial<Plotly.Data>[]>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<Partial<Plotly.Layout>>({});
  const [pcaEigenvectors, setPcaEigenvectors] = useState<number[][]>([]);
  const [barycentersSignature, setBarycentersSignature] = useState<Record<string, number[]>>({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [normalizedSignalDisplay, setNormalizedSignalDisplay] = useState<number[]>([]);
  const [barycentersCoordinates, setBarycentersCoordinates] = useState<Record<string, number[]>>({});
  const [currentModel, setCurrentModel] = useState<GenericModel | null>(null);
  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [displayedSeriesLabel, setDisplayedSeriesLabel] = useState<number[] | null>(null);
  const [pakagedDataDisplay, setPackageDataDisplay] = useState<packagedDisplay>({
    aggregatedRawMZIforDisplay: [],
    aggregatedRawMZIHumidityCorrectedforDisplay: [],
    aggregatedNormalizedforDisplay: [],
    aggregatedNormalizedHumidityCorrectedforDisplay: []
  })

  // State to store the computed derivatives
  const [rawSignalDerivative, setRawSignalDerivative] = useState<number[]>([]);
  const [correctedSignalDerivative, setCorrectedSignalDerivative] = useState<number[]>([]);

  // Refs to store the previous values of the signals
  const previousRawSignal = useRef<number[]>([]);
  const previousCorrectedSignal = useRef<number[]>([]);
  const previousTimestamp = useRef<number | null>(null);


  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const pcaTransform = (signatures: number[][], eigvec: number[][], nComponents: number): number[][] => {
    const U = new Matrix(eigvec);
    const dataset = new Matrix(signatures);
    const predictions = dataset.mmul(U);
    return predictions.subMatrix(0, predictions.rows - 1, 0, nComponents - 1).to2DArray();
  };

  const generateColorMap = (items: any[]) => {
    const colors = ['#FFD12C', '#7F7FFF', '#FF5733', '#C70039', '#900C3F', '#581845', '#FFC300', '#DAF7A6', '#581845', '#1ABC9C', '#2E86C1', '#A569BD'];
    const uniqueItems = Array.from(new Set(items));
    const colorMap: { [key: string]: string } = {};
    uniqueItems.forEach((item, index) => {
      colorMap[item] = colors[index % colors.length];
    });
    return colorMap;
  };

  // const updatePlot = (groupedProjections: Record<string, number[][]>, colors: Record<string, string>) => {
  //   const barycenters = computeBarycenters(groupedProjections);
  const updatePlot = (barycenters: Record<string, number[]>, colors: Record<string, string>) => {
    console.log('barycenters x,y', barycenters);
    const uniqueLabels = Object.keys(barycenters);

    const plotData: Partial<Plotly.ScatterData>[] = [
      {
        type: 'scatter',
        mode: 'markers',
        x: uniqueLabels.map((label) => barycenters[label][0]),
        y: uniqueLabels.map((label) => barycenters[label][1]),
        marker: {
          size: 10,
          color: uniqueLabels.map((label) => colors[label]),
        },
        text: uniqueLabels,
        hoverinfo: 'text',
      },
    ];

    setPlotlyData(plotData);

    const layout: Partial<Plotly.Layout> = {
      ...DEFAULT_PLOTLY_MARGIN,
      hovermode: 'closest',
      xaxis: { title: { text: 'First Component', font: { size: 12 } } },
      yaxis: { title: { text: 'Second Component', font: { size: 12 } } },
      dragmode: 'zoom',
      showlegend: false,
    };

    setPlotlyLayout(layout);
  };

  const normalizeSignal = (rawSignal: number[], seriesLabels: number[]): number[] => {
    // 1. Check that rawSignal is the same size as seriesLabels
    if (rawSignal.length !== seriesLabels.length) {
      throw new Error(`Raw signal length (${rawSignal.length}) does not match series labels length (${seriesLabels.length})`);
    }

    // 2. load values from model and get relevant positions.

    // load parameters from model for normalisation
    let minSpotFromModel = currentModel?.normalisationForChemicalClassification?.minSpot
    let maxSpotFromModel = currentModel?.normalisationForChemicalClassification?.maxSpot
    // get min and max spot from the model
    if (minSpotFromModel && maxSpotFromModel) {
      const minSpotIndex = seriesLabels.indexOf(minSpotFromModel);
      const maxSpotIndex = seriesLabels.indexOf(maxSpotFromModel);

      // Validate that we found both indices
      if (minSpotIndex === -1 || maxSpotIndex === -1) {
        throw new Error('Could not find required series labels: please update model');
      }
      // 3. Compute normalized values
      const minSpotValue = rawSignal[minSpotIndex];
      const maxSpotValue = rawSignal[maxSpotIndex];

      const normalizedSignal = rawSignal.map((value) => (value - minSpotValue) / (maxSpotValue - minSpotValue));
      return normalizedSignal;
    } else {
      console.log('model not correctly configured');
      return seriesLabels
    }



  };

  const reorderPeptides = (signal: number[], oldSeriesLabel: string[], newSeriesLabel: string[]): number[] => {
    let reorderedSignal: number[] = [];

    for (let label of newSeriesLabel) {
      reorderedSignal.push(signal[oldSeriesLabel.indexOf(label)]);
    }

    return reorderedSignal;
  };


  /**
 * Aggregates an array by calculating the median for groups of indices.
 * Optionally normalizes the aggregated medians using min-max normalization.
 *
 * @param aggregatedIndicesMap - A map where each key corresponds to an array of indices.
 * @param rawSignalFromApp - An array of raw signal values to be aggregated.
 * @param normalizeSignalBool - A boolean indicating whether to normalize the result.
 * @returns An array of medians (normalized if `normalizeSignal` is true).
 */
  function aggregateArray(
    aggregatedIndicesMap: Record<string, number[]>,
    rawSignalFromApp: number[],
    seriesLabel: number[],
    normalizeSignalBool: boolean
  ): number[] {
    let finalMZIs: number[] = [];

    for (let aggKey in aggregatedIndicesMap) {
      let aggIndices = aggregatedIndicesMap[aggKey];
      let values: number[] = [];

      for (let i = 0; i < aggIndices.length; i++) {
        values.push(rawSignalFromApp[aggIndices[i]]);
      }

      // Sort the values to calculate the median
      values.sort((a, b) => a - b);
      let median: number;
      let len = values.length;

      if (len % 2 === 0) {
        // Even number of elements, average the two middle values
        median = (values[len / 2 - 1] + values[len / 2]) / 2;
      } else {
        // Odd number of elements, take the middle value
        median = values[Math.floor(len / 2)];
      }

      finalMZIs.push(median);
    }

    // Normalize if the flag is set to true
    if (normalizeSignalBool) {
      finalMZIs = normalizeSignal(finalMZIs, seriesLabel);
    }

    return finalMZIs;
  }
  // handle model load from store
  useEffect(() => {
    let _model = loadGenericModel();
    console.log(' Selected Model is :', _model);

    if (_model !== null) {
      setCurrentModel(_model);
      setPcaEigenvectors(_model.projection_axis || []);

      // update plot with barycenters if defined
      if (_model.barycenters !== undefined) {
        setBarycentersCoordinates(_model.barycenters);
        if (_model.barycentersAsSignatures) setBarycentersSignature(_model.barycentersAsSignatures[0]);

        // update plot with pca data
        let chemicalFamilies = Object.keys(_model.barycenters);
        const colors = generateColorMap(chemicalFamilies);
        updatePlot(_model.barycenters, colors);
      }
    }

    // load spotgrid 1D for display in front end
    let _spotsgrid1d = loadSpotsGrid1D();
    setCurrentSpotsgrid1d(_spotsgrid1d);

    setIsDataLoaded(true);
  }, []);

  // aggregate 
  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
    // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
  }, []);


  // Handle raw signature updates
  useEffect(() => {
    if (!rawSignalFromApp || rawSignalFromApp === null || pcaEigenvectors.length === 0 || !isDataLoaded || currentModel === null) return;

    // compute signature if mean if higher than 1
    const mean = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length;

    if (seriesLabels && rawSignalFromApp && Math.abs(mean(rawSignalFromApp)) >= DISPLAY_RADIAN_THRESHOLD) {
      // const rawSignal = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120];

      // always aggregate in this chemical calibration mode : 

      // console.log('debugDataForDisplay', debugDataForDisplay);
      // console.log('rawSignalFromApp', rawSignalFromApp);
      let finalMZIs: number[] = [];
      for (let aggKey in aggregatedIndicesMap) {
        let aggIndices = aggregatedIndicesMap[aggKey];

        let values: number[] = [];
        for (let i = 0; i < aggIndices.length; i++) {
          values.push(rawSignalFromApp[aggIndices[i]]);
        }
        // Sort the values to calculate the median
        values.sort((a, b) => a - b);
        let median: number;
        let len = values.length;

        if (len % 2 === 0) {
          // Even number of elements, average the two middle values
          median = (values[len / 2 - 1] + values[len / 2]) / 2;
        } else {
          // Odd number of elements, take the middle value
          median = values[Math.floor(len / 2)];
        }
        finalMZIs.push(median);
      }
      setDisplayedSeriesLabel(Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey)))


      // seriesLabels = Object.keys(aggregatedIndicesMap).map((aggKey) => parseInt(aggKey));
      // console.log('rawsignalFromApp', rawSignalFromApp);
      // console.log('seriesLabels', seriesLabels);
      // let normalizedSignal:number[]
      if (displayedSeriesLabel && correctedSignalFromApp) {

        // Compute derivatives
        // Get the current timestamp
        const currentTimestamp = Date.now();

        // Compute the time interval (in seconds)
        const deltaTime =
          previousTimestamp.current !== null ? (currentTimestamp - previousTimestamp.current) / 1000 : 0;

        // Function to compute the derivative as signal change divided by time
        const computeDerivative = (current: number[], previous: number[], deltaTime: number): number[] => {
          if (deltaTime === 0 || previous.length === 0) {
            // If no previous timestamp or no previous data, return zeroed array
            return Array(current.length).fill(0);
          }
          return current.map((value, index) => {
            const deltaSignal = value - (previous[index] || 0);
            return deltaSignal / deltaTime; // Compute rate of change
          });
        };

        // Compute derivatives for both signals
        const rawDerivative = computeDerivative(rawSignalFromApp, previousRawSignal.current, deltaTime);
        const correctedDerivative = computeDerivative(correctedSignalFromApp, previousCorrectedSignal.current, deltaTime);

        // Update state with the computed derivatives
        setRawSignalDerivative(rawDerivative);
        setCorrectedSignalDerivative(correctedDerivative);

        // Update refs with current values
        previousRawSignal.current = [...rawSignalFromApp];
        previousCorrectedSignal.current = [...correctedSignalFromApp];
        previousTimestamp.current = currentTimestamp;
        //



        let packagedDisplay: packagedDisplay = {

          aggregatedRawMZIforDisplay: aggregateArray(aggregatedIndicesMap, rawSignalFromApp, displayedSeriesLabel, false),
          aggregatedRawMZIHumidityCorrectedforDisplay: aggregateArray(aggregatedIndicesMap, correctedSignalFromApp, displayedSeriesLabel, false),
          aggregatedNormalizedforDisplay: aggregateArray(aggregatedIndicesMap, rawSignalFromApp, displayedSeriesLabel, true),
          aggregatedNormalizedHumidityCorrectedforDisplay: aggregateArray(aggregatedIndicesMap, correctedSignalFromApp, displayedSeriesLabel, true)
        }


        // console.log('packagedDisplay', packagedDisplay);








        const normalizedSignal = normalizeSignal(finalMZIs, displayedSeriesLabel);
        // console.log('normalizedSignal', normalizedSignal);
        // console.log('displayedSeriesLabel', displayedSeriesLabel);
        setNormalizedSignalDisplay(normalizedSignal);
        setPackageDataDisplay(packagedDisplay)




        // prediction 


        try {
          // console.log('debug', currentModel, displayedSeriesLabel, displayedSeriesLabel);
          if (currentModel !== null && displayedSeriesLabel !== null && displayedSeriesLabel) {

            // 1. is signal strong enough ?
            let averageMZIcurrent = mean(finalMZIs);
            if (currentModel.chemicalRules && currentModel.chemicalRules.thresholds?.rawMziDetectionThreshold && averageMZIcurrent > currentModel.chemicalRules.thresholds?.rawMziDetectionThreshold) {
              // console.log("signal is significant... can be anayzed..")
            } else {
              // console.log("current signal is noise...")
            }

            // 2. is signal of type humidity ?


            const prediction = classifySignatureChemical(currentModel, normalizedSignal, displayedSeriesLabel)[0];
            console.log('prediction is', prediction);
            // setCurrentChemicalFamily(prediction);
            const packagedData: SecondData[] = [
              {
                id: 'example-id', // You can use any unique ID here
                content: Object.entries(currentModel.barycenters || {}).map(([key, value]) => ({
                  peptide: key,
                  ratio: key === prediction ? 35 : 7, // hardcode found chemical family : size 3 other wise 1.
                })),
              },
            ];
            setCurrentProba(packagedData);
          }
        } catch (error) {
          console.error(error);
        }
        const reorderedNormalisedSignal = reorderPeptides(
          normalizedSignal,
          seriesLabels.map((x) => x.toString()),
          currentModel.metadata.spotfile
        );
      }


      // ** distance computation

      // const distances: Record<string, number> = computeDistances(reorderedNormalisedSignal, barycentersSignature);
      // console.log('distances', distances);

      // const probabilities: Record<string, number> = softmax(distances);
      // console.log('proba', probabilities);

      // console.log('packagedData', packagedData);
      // Update state with the packaged data
    } else {
      let chemicalFamilies = Object.keys(barycentersCoordinates);

      const packagedData: SecondData[] = [
        {
          id: 'example-id', // You can use any unique ID here
          content: chemicalFamilies.map((family) => ({
            peptide: family,
            ratio: 7,
          })),
        },
      ];
      // setCurrentChemicalFamily('unknown');
      setCurrentProba(packagedData);
    }
  }, [rawSignalFromApp, pcaEigenvectors, isDataLoaded]);

  // function softmax(distances: Record<string, number>): Record<string, number> {
  //   const expDistances = Object.values(distances).map((distance) => Math.exp(-distance)); // Negative for inverse relationship
  //   const sumExpDistances = expDistances.reduce((sum, val) => sum + val, 0);
  //   const scaling_factor = 50;
  //   const probabilities: Record<string, number> = {};
  //   Object.keys(distances).forEach((key, index) => {
  //     probabilities[key] = (scaling_factor * expDistances[index]) / sumExpDistances;
  //   });

  //   return probabilities;
  // }

  // function euclideanDistance(vec1: number[], vec2: number[]): number {
  //   return Math.sqrt(vec1.reduce((sum, val, index) => sum + Math.pow(val - vec2[index], 2), 0));
  // }

  // function computeDistances(normalizedSignal: number[], barycentersSignature: Record<string, number[]>): Record<string, number> {
  //   const distances: Record<string, number> = {};

  //   for (const key in barycentersSignature) {
  //     distances[key] = euclideanDistance(normalizedSignal, barycentersSignature[key]);
  //   }

  //   return distances;
  // }

  return (
    <Paper style={{ width: '100%', margin: 'auto' }}>
      <Collapse>
        <Panel header="Numerical values" key="1">
          {/* <div>
            <h2>Normalized and non-humidity corrected signal:</h2>
            {normalizedSignalDisplay.map((value, index) => (
              <span key={index}>
                <strong>{displayedSeriesLabel && displayedSeriesLabel[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div> */}
          <div>
            <h2>Raw MZI:</h2>
            {pakagedDataDisplay.aggregatedRawMZIforDisplay.map((value, index) => (
              <span key={index}>
                <strong>{displayedSeriesLabel && displayedSeriesLabel[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>
          <div>
            <h2>Raw humidity corrected MZI:</h2>
            {pakagedDataDisplay.aggregatedRawMZIHumidityCorrectedforDisplay.map((value, index) => (
              <span key={index}>
                <strong>{displayedSeriesLabel && displayedSeriesLabel[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>
          <div>
            <h2>Normalized MZI:</h2>
            {pakagedDataDisplay.aggregatedNormalizedforDisplay.map((value, index) => (
              <span key={index}>
                <strong>{displayedSeriesLabel && displayedSeriesLabel[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>
          <div>
            <h2>Normalized humidity corrected MZI:</h2>
            {pakagedDataDisplay.aggregatedNormalizedHumidityCorrectedforDisplay.map((value, index) => (
              <span key={index}>
                <strong>{displayedSeriesLabel && displayedSeriesLabel[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>
          <div>
            <h2>Derivative MZI:</h2>
            {rawSignalDerivative.map((value, index) => (
              <span key={index}>
                <strong>{seriesLabels && seriesLabels[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>
          <div>
            <h2>Derivative humidity corrected MZI:</h2>
            {correctedSignalDerivative.map((value, index) => (
              <span key={index}>
                <strong>{seriesLabels && seriesLabels[index]}</strong>: {value.toFixed(2)}{' '}
              </span>
            ))}
          </div>

        </Panel>
      </Collapse>
    </Paper>
  );
}

export default DataProcessor;
