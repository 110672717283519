import { useEffect, useRef, useState } from 'react';
import BaselineRecording from './OdorIdentifiacationSteps/BaselineRecording';
import AnalyteRecording from './OdorIdentifiacationSteps/AnalyteRecording';
import OdorAnalysis from './OdorIdentifiacationSteps/OdorAnalysis';
import OdorDisplay from './OdorIdentifiacationSteps/OdorDisplay';
import GenericDisplay from './OdorIdentifiacationSteps/GenericDisplay';
import SensorCleaning from './OdorIdentifiacationSteps/SensorCleaning';
import { DeviceValue, RecordKey, commitSensogramPartition } from '../../services/cache/idb';
import { Mutex, MutexInterface, withTimeout } from 'async-mutex';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, parseBiosensorsSignalEvent, parseEventPayload } from '../../components/serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { loadModel, loadGenericModel, loadSpotsGrid1D } from '../../services/cache/localStorage';
import {
  DEFAULT_ANALYSIS_COMPARISON_THRESHOLD,
  DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC,
  DEFAULT_PLOT_DECIMATED_FPS,
  DEFAULT_RAW_FPS,
  DEFAULT_STORAGE_DECIMATED_FPS,
  IDB_PARTITION_WINDOW_SIZE,
  PLOT_WINDOW_SIZE,
} from '../../utils/constants/constants';
import { mean, standardDeviation, transpose } from '../../components/analysis/utils';
import { DEFAULT_ODOR_PRESENCE_DEACTIVATION_PERCENT_OF_MAX_VALUE } from '../../components/serial/constants';
import { DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE, PEPTIDE_COLOR_MAP_VDW } from '../../utils/helpers/utils';
import { aggregateSignature, normalizeL2, sortSignature, softmaxForDistances, parseMessagePayload, processMziData, updateKineticSeries, shouldCompute, updateQuestioningState, minmaxNormaliseWithGenericModelSpotDefinition } from '../../components/analysis/compute';
import { GenericModel, ModelCategory } from '../../components/analysis/definitions';
import { QuestionningResult, SignatureWithSpotgrid } from '../../types/types';
import { classifySignature, classifySignatureChemical, classifySignatureWithModel } from '../../components/analysis/classifier';
import Plot from 'react-plotly.js';
import { DEFAULT_PLOTLY_CONFIG, DEFAULT_PLOTLY_LAYOUT } from '../../utils/constants/constants';
import { getSignatureFigure, getFigureFromGenericModel } from '../../components/analysis/figures';
import { signature } from '../../components/analysis/definitions';
import { pcaTransform } from '../../components/analysis/pca';
import type { InputNumberProps } from 'antd';
import { Button, Card, Col, InputNumber, Row, Slider, Space, Switch } from 'antd';
import { Paper } from '../../components/common/common';
import { ReloadOutlined } from '@ant-design/icons';
import { IntensityGauge } from '../../components/widgets/Gauges/IntensityGauge';
import { useMessageContext } from '../../state/context/MessageContext';
import { SecondData } from '../ChemicalFamilyIdentificationPage/CsmChemicalFamilyIdentificationPage';
import BubbleChart from '../../components/widgets/ChemicalFamilyIdentification/BubbleChart';
import { Typography } from 'antd';

type CsmOdorIdentificationProps = {};
enum QuestioningState {
  SensorCleaning = 'SensorCleaning',
  BaselineRecording = 'BaselineRecording',
  AnalyteRecording = 'AnalyteRecording',
  GenericDisplay = 'GenericDisplay',
  OdorAnalysis = 'OdorAnalysis',
  OdorDisplay = 'OdorDisplay',
}

enum SenseMode {
  Recording,
  Questionning,
}

export const CsmOdorIdentification: React.FC<CsmOdorIdentificationProps> = () => {
  const [rawQuestionningSignature, setRawQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSignature, setQuestionningSignature] = useState<number[] | null>(null);
  const [questionningSpotsgrid1d, setQuestionningSpotsgrid1d] = useState<number[] | null>(null);

  const { csmMessages, csmIsConnected, consumeCSMMessage, clearCSMMessages, addCSMCommand, hihValues } = useMessageContext();

  // * humidity compensation
  const [humidityCalibrant, setHumidityCalibrant] = useState<SignatureWithSpotgrid | undefined>(undefined);
  const humidityBaselineRef = useRef<number | null>(null);
  const [humidityCompensationEnabled, sethumidityCompensationEnabled] = useState<boolean>(false);
  // *

  // * MZI and timestamps related useRefs
  const firstMZIsRef = useRef<number[] | null>(null);
  const previousMZIsRef = useRef<number[] | null>(null);
  const signalEnvelopeAvgRef = useRef<number>(0);
  const signalMZI = useRef<number[]>([]);

  const rawMZISeriesRef = useRef<number[][]>([]);
  const averageMZISeriesRef = useRef<number[]>([]);
  const isMZIcomputingrequested = useRef<boolean>(false);

  // * HIH useRefs
  const humidityDisplayed = useRef<number>(0);
  const temperatureDisplayed = useRef<number>(0);
  // *

  // * ALGO
  //
  const decimatedMZISeriesCorrected = useRef<number[][]>([]);
  const questioningState = useRef<QuestioningState>(QuestioningState.BaselineRecording);

  const [mziUplotOptions, setMziUplotOptions] = useState<uPlot.Options | null>(null);

  const [mziUplotData, setMziUplotData] = useState<uPlot.AlignedData>([]);
  const [fpsUplotData, setFpsUplotData] = useState<uPlot.AlignedData>([]);

  const mziTargetRef = useRef<HTMLDivElement>(null);
  const mziUplotRef = useRef<uPlot | null>(null);
  const mziTooltipRef = useRef<HTMLDivElement>(null);

  const fpsTargetRef = useRef<HTMLDivElement>(null);
  const fpsUplotRef = useRef<uPlot | null>(null);

  const KsRef = useRef<number[] | null>(null);

  const noizeLevelRef = useRef<number>(0);
  const isOdorPresentRef = useRef<boolean>(false);
  const odorPresenceThresholdLevelRef = useRef<number>(0);
  const maxOdorPresentValue = useRef<number>(0);
  const odorPresentStartTimestampRef = useRef<number>(0);
  const odorPresentStopTimestampRef = useRef<number>(0);
  const odorPresentLastRecognitionTimestampRef = useRef<number>(0);
  const signalEnvelopeMinRef = useRef<number>(0);
  const signalEnvelopeMaxRef = useRef<number>(0);

  const decimatedMZISeriesRef = useRef<number[][]>([]);
  const analyteMZISeriesRef = useRef<number[][]>([]);

  const decimatedTimestampSeriesRef = useRef<number[]>([]);
  const rawTimestampSeriesRef = useRef<number[]>([]);

  const decimatedMZIPartitionSeriesRef = useRef<number[][]>([]);
  const decimatedTimestampPartitionSeriesRef = useRef<number[]>([]);

  const decimatedFpsTimeseriesRef = useRef<number[]>([]);
  const rawFpsTimeseriesRef = useRef<number[]>([]);

  const [rawFps, setRawFps] = useState<number>(0);
  const [decimatedFps, setDecimatedFps] = useState<number>(0);

  const startTickRef = useRef<number>(0);
  const lastDecimationTickRef = useRef<number>(0);

  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);
  const [aggregatedIndicesMap, setAggregatedIndicesMap] = useState<Record<number, number[]>>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const recordKeyRef = useRef<RecordKey | null>(null);

  const [isSensing, setIsSensing] = useState<boolean>(true);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordStartTimestamp, setRecordStartTimestamp] = useState<number>(0);

  const [deviceValue, setDeviceValue] = useState<DeviceValue | null>(null);
  const [isRecordModalOpen, setIsRecordModalOpen] = useState<boolean>(false);

  const [shouldAggregate, setShouldAggregate] = useState<boolean>(true);
  const [showDebugInfo, setShowDebugInfo] = useState<boolean>(false);
  const [shouldRedraw, setShouldRedraw] = useState<boolean>(false);
  const [pinLastQuestionningResult, setPinLastQuestionningResult] = useState<boolean>(true);

  const [senseMode, setSenseMode] = useState<SenseMode>(SenseMode.Recording);

  const messageQueueMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 300));

  const [currentModel, setCurrentModel] = useState<GenericModel | null>(null);
  // const [currentModel, setCurrentModel] = useState<GenericModel | null>(null); back to image display for September demo
  const [questionningResult, setQuestionningResult] = useState<QuestionningResult | null>(null);

  // random result
  const [plotlyData, setPlotlyData] = useState<Plotly.Data[]>([]);
  const [plotlyLayout, setPlotlyLayout] = useState<Partial<Plotly.Layout>>({});

  const [signatureWindowValue, setSignatureWindowValue] = useState(DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
  const [thresholdOverride, setThresholdOverride] = useState(DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);


  // chemical bubble display 
  const [currentProba, setCurrentProba] = useState<SecondData[]>([]);

  // reset MZI for display centered around 0
  const onClickReset = () => {
    questioningState.current = QuestioningState.BaselineRecording;
    setQuestionningSignature([]);
    // previousMZIsRef.current = null is to avoid synthetic phase jumps when signal drops from some value > pi/2 to zero
    previousMZIsRef.current = null;
    firstMZIsRef.current = null;

    noizeLevelRef.current = 0;
    isOdorPresentRef.current = false;
    odorPresenceThresholdLevelRef.current = 0;
    maxOdorPresentValue.current = 0;
    odorPresentStartTimestampRef.current = 0;
    odorPresentStopTimestampRef.current = 0;
    odorPresentLastRecognitionTimestampRef.current = 0;
    signalEnvelopeMinRef.current = 0;
    signalEnvelopeMaxRef.current = 0;
    signalEnvelopeAvgRef.current = 0;

    decimatedMZISeriesRef.current = [];
    rawMZISeriesRef.current = [];
    analyteMZISeriesRef.current = [];

    decimatedTimestampSeriesRef.current = [];
    rawTimestampSeriesRef.current = [];
    decimatedMZIPartitionSeriesRef.current = [];
    decimatedTimestampPartitionSeriesRef.current = [];
    decimatedFpsTimeseriesRef.current = [];
    rawFpsTimeseriesRef.current = [];

    setQuestionningResult(null);
  };

  // esp32 communication use State
  const [esp32Ip, setEsp32Ip] = useState<string>("https://192.168.27.45"); // Default value
  const [inputIp, setInputIp] = useState<string>("");
  // esp32 commmunication tooling

  // Function to send a command to the ESP32
  const sendDebugCommandToESP = async (command: string): Promise<void> => {
    try {
      console.log('request:', `${inputIp}/${command}`);
      const response = await fetch('https://' + `${inputIp}/${command}`, { method: 'GET' }); // Replace 'your-endpoint' with the actual endpoint
      if (!response.ok) {
        throw new Error(`HTTPS error! Status: ${response.status}`);
      }
      const text = await response.text();
      console.log(`Response: ${text}`);
    } catch (error) {
      console.error("Error sending command:", error);
    }
  };



  const sendStimulationParametersCommandToESP = async (parameters: Record<string, any>): Promise<void> => {
    const url = 'https://' + esp32Ip + `/generic-mechanical-stimulation?frequency=${parameters.frequency}&duration=${parameters.duration}`; // Replace 'your-endpoint' with the actual endpoint
    console.log('url', url);
    try {
      console.log('request:', url, 'parameters:', parameters);
      const response = await fetch(url, {
        method: 'GET'

        // body: JSON.stringify(parameters),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const text = await response.text();
      console.log(`Response: ${text}`);
    } catch (error) {
      console.error("Error sending command:", error);
    }
  };

  // Function to update the ESP32 IP address
  const updateIpAddress = () => {
    if (inputIp.trim()) {
      setEsp32Ip(inputIp.trim());
      console.log(`ESP32 IP updated to: ${inputIp}`);
    } else {
      alert("Please enter a valid IP address.");
    }
  };


  const onSignatureWindowSliderChange: InputNumberProps['onChange'] = (newValue) => {
    setSignatureWindowValue(newValue as number);
  };

  const onComparisonThresholdChange: InputNumberProps['onChange'] = (newValue) => {
    if (newValue !== null) {
      setThresholdOverride(newValue as number);
      if (currentModel !== null) {
        setCurrentModel({
          ...currentModel,
          comparisonThreshold: newValue as number,
        });
      }
    }
  };

  useEffect(() => {
    clearCSMMessages();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.StartSampling,
      },
    });
    setIsLoading(false);
    return () => {
      addCSMCommand({
        id: uuidv4().toString(),
        message: {
          CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
        },
      });
      setIsLoading(true);
    };
  }, []);

  useEffect(() => {
    let _spotsgrid1d = loadSpotsGrid1D();
    if (!_spotsgrid1d) {
      console.log('sense page: spotsgrid1d is empty');
      return;
    }
    setCurrentSpotsgrid1d(_spotsgrid1d);
    // Aggregate MZIs by peptide
    let _aggregationIndicesMap: Record<number, number[]> = {};
    for (let i = 0; i < _spotsgrid1d.length; i++) {
      let aggKey = _spotsgrid1d[i];
      if (aggKey < 0) {
        continue;
      }
      if (_aggregationIndicesMap[aggKey] === undefined) {
        _aggregationIndicesMap[aggKey] = [];
      }
      _aggregationIndicesMap[aggKey].push(i);
    }
    setAggregatedIndicesMap(_aggregationIndicesMap);
    // console.log("sense page: _aggregationIndicesMap", _aggregationIndicesMap)
  }, []);

  useEffect(() => {
    if (csmMessages.length === 0) {
      return;
    }
    if (messageQueueMutexRef.current.isLocked()) {
      return;
    }
    // console.log("sense page: acquiring mutex..")

    // implement me : unfoldBiosensorEvent

    messageQueueMutexRef.current
      .acquire()
      .then((release) => {
        let nFramesProcessed = 0;

        csmMessages.forEach((message) => {
          if (message.message.Type !== CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent) {
            console.log('sense page: csm ble message is not a biosensors signal event', message.message);
            consumeCSMMessage(message.id);
            return;
          } else {
            nFramesProcessed++;
            consumeCSMMessage(message.id);

            // Parse message payload
            let mzis = parseMessagePayload(message);

            if (!mzis) return;

            // set Baseline delta H for future use
            if (humidityBaselineRef.current === null) {
              console.log('Changing humidity baseline reference as ', hihValues.humidity);
              humidityBaselineRef.current = hihValues.humidity;
            }

            if (firstMZIsRef.current === null) {
              firstMZIsRef.current = [...mzis];
            }

            isMZIcomputingrequested.current = shouldCompute(message.ts, lastDecimationTickRef); // shouldCompute is in fact decimation
            let [averageMZI, correctedMZI] = processMziData(mzis, rawMZISeriesRef.current, firstMZIsRef.current, currentSpotsgrid1d, humidityCompensationEnabled, humidityCalibrant, humidityBaselineRef.current, hihValues) || [0, []];

            updateKineticSeries(averageMZI, correctedMZI, averageMZISeriesRef, decimatedMZISeriesCorrected);

            if (isMZIcomputingrequested.current) {
              // to prevent frequent rendereing
              // console.log('averageMZI...', averageMZI, ' humidity correction is', humidityCompensationEnabled, 'lastDecimationTickRef.current is', lastDecimationTickRef.current)

              // Update displayed values
              signalEnvelopeAvgRef.current = averageMZI; // for display in the component
              signalMZI.current = correctedMZI;
              humidityDisplayed.current = hihValues.humidity;
              temperatureDisplayed.current = hihValues.temperature;

              // * here nightmare starts ..
              // update algo state
              updateQuestioningState(questioningState, averageMZI, averageMZISeriesRef.current, decimatedMZISeriesCorrected.current, isOdorPresentRef, maxOdorPresentValue, odorPresenceThresholdLevelRef, noizeLevelRef);
              console.log('algo state is', questioningState.current);
              // * that was not so hard
            }
          }
        });
        // console.log('processed nFramesOnOneMutexLock', nFramesOnOneMutexLock)
        rawMZISeriesRef.current = []; // flushing
        release();
      })
      .catch((e: any) => {
        console.log('sense page: could not acquire mutex', e);
        messageQueueMutexRef.current.cancel();
        messageQueueMutexRef.current.release();
      });
    return () => {
      messageQueueMutexRef.current.cancel();
      messageQueueMutexRef.current.release();
    };
  }, [csmMessages]);

  useEffect(() => {
    const constructDeviceValue = async () => {
      let commonName = 'Neose CSM BLE';
      if (commonName === undefined || commonName === null) {
        commonName = '';
      }
      let shellSerial = '';
      let coreSensorSerial = '';
      let fwVersion = '';
      let hwVersion = '';
      let cameraExposure = 0;

      let spotsgrid = currentSpotsgrid1d;
      if (spotsgrid === undefined || spotsgrid === null) {
        throw new Error('spotsgrid is undefined');
      }

      let _deviceValue = {
        commonName,
        shellSerial,
        coreSensorSerial,
        fwVersion,
        hwVersion,
        cameraExposure,
        spotsgrid,
      };
      console.log('sense page: constructed device value', _deviceValue);
      return _deviceValue;
    };
    constructDeviceValue()
      .then((_deviceValue) => {
        setDeviceValue(_deviceValue);
      })
      .catch((e: any) => {
        console.log('sense page: could not construct device', e);
      });
  }, [currentSpotsgrid1d]);

  useEffect(() => {
    // if (questioningState !== QuestioningState.OdorAnalysis) return;
    let _model = loadGenericModel();
    console.log(' Selected Model is :', _model);
    // let _model = loadModel();
    setSignatureWindowValue(_model?.analyteDuration || DEFAULT_IMMEDIATE_RECOGNITION_BACKWARD_WINDOW_SEC);
    setThresholdOverride(_model?.comparisonThreshold || DEFAULT_ANALYSIS_COMPARISON_THRESHOLD);
    setCurrentModel(_model);
  }, []);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorAnalysis) return;
    if (questionningSignature === null) {
      console.log('questioning result widget: null signature');
      setQuestionningResult(null);
      return;
    }
    if (questionningSpotsgrid1d === null) {
      console.log('questioning result widget: null spotsgrid1d');
      return;
    }
    if (currentModel === null) {
      console.log('questioning result widget: received signature upon null model');
      return;
    }



    // classify according to the model type

    if (currentModel?.metadata.type === ModelCategory.ChemicalPrediction) {
      // console.log('getting chemical prediction questionning spotgrid is', questionningSpotsgrid1d);
      const prediction = classifySignatureChemical(currentModel, questionningSignature, questionningSpotsgrid1d)[0];
      console.log('prediction is', prediction);

      // prepare request to push to the esp32


      if (currentModel.isRoseDemo && currentModel.classToStimulationDictionnary) {
        const classToStimulationDictionnary = currentModel.classToStimulationDictionnary;
        console.log('classToStimulationDictionnary', classToStimulationDictionnary);
        type StimulationKeys = keyof typeof classToStimulationDictionnary;
        const stimulationKey = Object.keys(classToStimulationDictionnary).find(key => key === prediction) as StimulationKeys | undefined;
        const stimulationValue = stimulationKey ? classToStimulationDictionnary[stimulationKey] : null;
        console.log(stimulationValue);

        // push to esp32
        if (stimulationValue) {
          sendStimulationParametersCommandToESP(stimulationValue);
        }



      }
      // set in structure to display bubble later 
      const packagedData: SecondData[] = [
        {
          id: 'example-id', // You can use any unique ID here
          content: Object.entries(currentModel.barycenters || {}).map(([key, value]) => ({
            peptide: key,
            ratio: key === prediction ? 35 : 7, // hardcode found chemical family : size 3 other wise 1.
          })),
        },
      ];
      console.log('packagedData', packagedData);
      setCurrentProba(packagedData);

    } else {
      console.log('other type of model - not chemical');
    }
    let [label, point] = classifySignatureWithModel(currentModel, questionningSignature, questionningSpotsgrid1d);

    let _questionningResult: QuestionningResult = {
      label: label,
      point: point,
    };
    console.log('Model Threshold is :', currentModel.comparisonThreshold);
    console.log('Questionning result is :', _questionningResult);
    setQuestionningResult(_questionningResult);
    questioningState.current = QuestioningState.OdorDisplay;
  }, [questionningSignature, currentModel]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorDisplay) return;
    setTimeout(() => {
      questioningState.current = QuestioningState.SensorCleaning;
    }, 10000);
  }, [questioningState, questionningSignature]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.SensorCleaning) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) {
      // purge analyteMZISeriesRef
      analyteMZISeriesRef.current = [];
      questioningState.current = QuestioningState.BaselineRecording;
    }
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.OdorAnalysis) return;
    if (Math.round(100 * Number(signalEnvelopeAvgRef.current)) / 100 <= 0.2) {
      // purge analyteMZISeriesRef
      analyteMZISeriesRef.current = [];
      questioningState.current = QuestioningState.BaselineRecording;
    } else {
      // compute signature and return
      // console.log('compute and return');


      constructSignature();
    }
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  useEffect(() => {
    if (questioningState.current !== QuestioningState.AnalyteRecording) return;

    // capture frames once analyte is started during a specified signatureWindowValue amount of time
    if (analyteMZISeriesRef.current.length < DEFAULT_PLOT_DECIMATED_FPS * signatureWindowValue) {
      analyteMZISeriesRef.current.push(signalMZI.current);
    }
    console.log('analyte buffer is ', analyteMZISeriesRef.current);
    // buffer is filled and kept at initial value, not sliding value. We could implement a toggle if the user prefer the initial signature or the sliding one
  }, [questioningState.current, signalEnvelopeAvgRef.current]);

  const constructSignature = (idxStart?: number) => {
    if (idxStart === undefined) {
      idxStart = -DEFAULT_PLOT_DECIMATED_FPS * signatureWindowValue;
    }
    let sectionMZIs = analyteMZISeriesRef.current;
    let sectionMZIsSpans = transpose(sectionMZIs);

    if (!currentSpotsgrid1d) {
      console.log('sense page: spotsgrid is empty');
      return;
    }

    // signature with no baseline substraction, simple analyte mean
    let _signature = sectionMZIsSpans.map((mzis) => mean(mzis));
    let excludedSignature: number[] = [];
    let excludedSpotsgrid1d: number[] = [];
    for (let i = 0; i < currentSpotsgrid1d.length; i++) {
      let sensorInt = currentSpotsgrid1d[i];
      if (sensorInt >= 1) {
        excludedSignature.push(_signature[i]);
        excludedSpotsgrid1d.push(sensorInt);
      }
    }

    let finalSignature: number[] = [];
    let finalSpotsgrid1d: number[] = [];

    // always aggregate by common spot name
    let [aggregatedSignature, aggregatedSpotsgrid1d] = aggregateSignature(excludedSignature, excludedSpotsgrid1d);
    finalSignature = aggregatedSignature;
    finalSpotsgrid1d = aggregatedSpotsgrid1d;

    let [sortedFinaleSignature, sortedFinalSpotsgrid1d] = sortSignature(finalSpotsgrid1d, finalSignature);
    // console.log('finalSpotsgrid1d', finalSpotsgrid1d)
    // console.log('sortedFinalSpotsgrid1d', sortedFinalSpotsgrid1d);
    // disable normallisation when comparing intensities
    if (currentModel?.metadata?.type === ModelCategory.ComparisonIntensities) {
      setQuestionningSignature(sortedFinaleSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    } else {
      let normalizedSortedAggregatedSignature = normalizeL2(sortedFinaleSignature);
      setQuestionningSignature(normalizedSortedAggregatedSignature);
      setQuestionningSpotsgrid1d(sortedFinalSpotsgrid1d);
    }
    if (currentModel?.metadata.type === ModelCategory.ChemicalPrediction) {
      // console.log('chemical model detected... ');
      // console.log('raw siganture', finalSignature);
      console.log('min max signature', minmaxNormaliseWithGenericModelSpotDefinition(aggregatedSignature, finalSpotsgrid1d, currentModel));
      setQuestionningSignature(minmaxNormaliseWithGenericModelSpotDefinition(aggregatedSignature, finalSpotsgrid1d, currentModel));
      setQuestionningSpotsgrid1d(finalSpotsgrid1d);
    }
  };

  // console.log(currentModel)
  return (
    <>
      <Row>
        <Paper>Current model : {currentModel?.metadata.ID}</Paper>
      </Row>
      {currentModel?.metadata.debug && (
        <>
          <Row>
            <Col span={12}>
              Analyte duration
              <Slider min={1} max={20} onChange={onSignatureWindowSliderChange} value={typeof signatureWindowValue === 'number' ? signatureWindowValue : 0} />
            </Col>
            <Col span={4}>
              <InputNumber min={1} max={20} style={{ margin: '0 16px' }} value={signatureWindowValue} onChange={onSignatureWindowSliderChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              Comparison Threshold
              <InputNumber min={0} style={{ margin: '0 16px' }} value={thresholdOverride} onChange={onComparisonThresholdChange} />
            </Col>
            <Col>
              <span>
                <span style={{ color: 'green' }}>Noise Level</span> <b>{noizeLevelRef.current.toFixed(2)}</b>
              </span>
            </Col>
          </Row>
        </>
      )}
      {questioningState.current === QuestioningState.BaselineRecording && (
        <BaselineRecording MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.AnalyteRecording && (
        <AnalyteRecording MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />
      )}
      {questioningState.current === QuestioningState.OdorAnalysis && <OdorAnalysis />}
      {questioningState.current === QuestioningState.GenericDisplay && <GenericDisplay result={questionningResult} />}
      {questioningState.current === QuestioningState.OdorDisplay && currentModel?.metadata.type === ModelCategory.ChemicalPrediction ? <BubbleChart data={currentProba} currentChemicalFamily={'toto'} /> : <OdorDisplay result={questionningResult} />}
      {questioningState.current === QuestioningState.SensorCleaning && <SensorCleaning MZIvalue={parseFloat(signalEnvelopeAvgRef.current.toFixed(1))} hihValues={{ humidity: humidityDisplayed.current, temperature: temperatureDisplayed.current }} />}

      <Col span={24} style={{ padding: 20, borderRadius: 20, textAlign: 'center' }}>
        <Row justify="center">
          <Button
            type="primary"
            icon={<ReloadOutlined />} // Add the reset icon
            onClick={onClickReset} // Trigger onClickReset when the button is clicked
          ></Button>
        </Row>
      </Col>
      <Col span={20} style={{ padding: 20, borderRadius: 20, textAlign: 'center' }}>
        <div>Humidity correction enabled</div>
        <Switch
          onChange={sethumidityCompensationEnabled}
          defaultChecked={humidityCompensationEnabled} // Trigger onClickReset when the button is clicked
        />
      </Col>
      <Col>
        <h1>ESP32 Control</h1>
        <div>
          <label htmlFor="ipAddress">ESP32 IP Address:</label>
          <input
            id="ipAddress"
            type="text"
            placeholder="Enter ESP32 IP"
            value={inputIp}
            onChange={(e) => setInputIp(e.target.value)}
          />
          <button onClick={updateIpAddress}>Save IP</button>
        </div>
        <p>Current ESP32 IP: {esp32Ip}</p>
        <button onClick={() => sendDebugCommandToESP("low")}>Low</button>
        <button onClick={() => sendDebugCommandToESP("high")}>High</button>
        <button onClick={() => sendStimulationParametersCommandToESP({ frequency: 10, duration: 5000 })}>Low f Stim</button>
        <button onClick={() => sendStimulationParametersCommandToESP({ frequency: 100, duration: 5000 })}>High f Stim </button>
      </Col>
    </>
  );
};
